html {
  overflow-y: hidden;
}

.spinner {
  display: flex;
  justify-content: center;
  height: 20rem;
  width: 20rem;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.no-padding-cell {
  padding-left: 0 !important; /* Remove left padding from cell */
}

.applogo {
  width: 100%;
  height: 5rem;
}

.arrow-icon {
  width: 10px;
  height: 10px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  transform: rotate(45deg);
}

.submitButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
}

.submitButton:hover {
  background-color: #004080;
}

.submitButton:focus {
  outline: none;
}

.inputBox {
  border: 2px solid #00c3ff;
  padding: 10px;
  font-size: 14px;
  border-radius: 3px;
  width: 150px;
  margin-bottom: 16px; /* Adjust the margin as needed */
}

.inputBox:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 5px #0056b3;
}

.labelText {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #5cadc1d5;
  margin-bottom: 8px;
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px; /* Rounded shape */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%; /* Rounded shape */
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider:before {
  border-radius: 50%;
}

.slider-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 14px;
  color: #423636;
}

.toggle-container {
  display: flex;
  align-items: left;
  justify-content: center;
  margin-bottom: 10px; /* Optional margin for spacing between containers */
}
.toggle-container-left {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-bottom: 10px; /* Optional margin for spacing between containers */
}

.toggle-container .toggle-button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.dropdown-select {
  padding: 8px;
  font-size: 18px;
  border-radius: 4px;
  border: 4px solid #fdfdfd00;
  background-color: #cdcbc5;
  border-radius: 2px;
  color: rgb(
    0,
    128,
    255
  ); /* Replace with your desired color */
  width: 200px;
}

.toggle-container1 {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-bottom: 10px; /* Optional margin for spacing between containers */
}

.toggle-container1 .toggle-button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-text {
  margin-left: 20px;
  margin-right: 10px;
  font-size: 22px;
  color: rgb(0, 128, 255);
  font-weight: bold;
}

.toggle-text1 {
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  color: #ff9d00;
  font-weight: bold;
}

.toggle-text2 {
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  color: rgb(0, 128, 255);
  font-weight: bold;
}

.main-label {
  color: #ff9d00;
  font-weight: bold;
}

.sub-label {
  color: #0084ff;
}

.additional-text {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
}

.hovered {
  background-color: #f5f5f5;
  color: #000;
  padding: 5px;
  border-radius: 5px;
}

.container {
  margin-top: 5px;
  background-color: #f5f5f500;
  padding: 5px;
  border: 1px solid #cccccc00;
  border-radius: 3px;
}

.duration-buttons {
  margin-bottom: 10px;
}

.duration-buttons button {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid teal;
}

.duration-buttons button.active {
  background-color: #3366ff;
  color: #ffffff;
}

.chart-container {
  width: 100%;
  height: 400px;
}

.csv-link {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
  text-decoration: none;
}

.csv-link:hover {
  background-color: #ddd;
}

.success-message {
  font-size: 14px;
}

.radio-button {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 50%;
}

.radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.radio-button-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  display: none;
}

.radio-button
  input[type="radio"]:checked
  + .radio-button-indicator {
  display: block;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking-text {
  animation: blink 2s linear infinite;
}
.new-label {
  color: #ffa500;
  font-style: italic;
  font-size: 10px;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
}

.success-message-container {
  display: flex;
  flex-direction: column;
}

.group-message {
  margin-bottom: 10px;
}

.group-name {
  font-weight: bold;
  margin-right: 5px;
  color: #595b5b;
}

.group-content {
  white-space: pre-wrap;
}

.job-id {
  font-weight: bold;
  color: #cfc920;
}
.gap {
  margin-bottom: 0.5em; /* Adjust the margin as needed */
}

/* Add this to your styles or CSS file */
.file-uploader-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

.dropzone {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone.active {
  background-color: #f0f0f0;
}

.file-content {
  margin-top: 20px;
}

.error-message {
  color: red;
}

.file-content-popup {
  max-width: 600px; /* Adjust the maximum width as needed */
  max-height: 400px; /* Set the maximum height for vertical scrolling */
  overflow-y: auto; /* Enable vertical scrolling when the content exceeds the height */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align the content to the left within the popup */
}

.file-content-popup pre {
  white-space: pre-wrap; /* Preserve line breaks */
}

.ag-theme-quartz .ag-cell-value {
  line-height: 20px !important;
  padding-top: 10px;
  padding-bottom: 25px;
}

.ag-floating-filter-input input {
  font-size: 11px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
