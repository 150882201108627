.custom-calendar {
  /* Add your custom styles here */
  font-family: roboto;
  background-color: #f3f2fd;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.rbc-calendar {
  border: none; /* Removes the border */
  box-shadow: none; /* Removes any shadow (if applied) */
  display: flex;
  flex-direction: column;
  gap: 1.67rem;
}

/* Example of styling specific parts of the calendar */
.rbc-toolbar {
  background-color: #f3f2fd;
  /* border-bottom: 1px solid #ddd; */
}
.rbc-allday-cell {
  display: none;
}

.rbc-month-view {
  background-color: #faf8ff;
  border: none !important;
}

/* Styling the grid and grid lines */
.rbc-time-view {
  border: none;
}

.rbc-time-header {
  background-color: #f3f2fd;
}

.rbc-time-content {
  border-top: 1px solid;
}

.rbc-time-gutter .rbc-time-slot {
  border: none;
  position: relative;
}

.rbc-label {
  color: #4a4a4a;

  /* material-theme/body/small */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
}

.rbc-day-bg {
  /* border-right: 1px solid #c4c5d6 !important; */
  border-left: none;
  background-color: #f3f2fd;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #c4c5d6 !important;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #c4c5d6 !important;
}

.rbc-button-link {
  text-align: center;
}

.rbc-day-slot .rbc-time-slot {
  border: none;
}

.rbc-now .rbc-button-link {
  background-color: #2d55c9;
  color: #fff;
  padding: 0.3rem;
  border-radius: 50%;
}

.rbc-time-content {
  border-top: 1px solid #c4c5d6 !important;
}

.rbc-time-column .rbc-timeslot-group {
  border-bottom: none;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #c4c5d6 !important;
  border-bottom: 1px solid #c4c5d6 !important;
}

.rbc-time-header-cell {
  border-right: none;
}

.rbc-date-cell {
  display: flex;
  justify-content: center;
}

.rbc-button-link {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #444746;
  line-height: 16px;
}

/* header related changes */

.rbc-header {
  display: "flex";
  justify-content: center;
  border-bottom: none;
  border-top: none;
  background-color: #f3f2fd;
  /* border-right: 1px solid #c4c5d6 !important; */
}

.rbc-header span {
  color: #444653;
  /* M3/body/large */
  font-family: var(--Body-Large-Font, Roboto);
  font-size: var(--Body-Large-Size, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(
    --Body-Large-Line-Height,
    24px
  ); /* 150% */
  letter-spacing: var(--Body-Large-Tracking, 0.5px);
}

.rbc-header + .rbc-header {
  border-left: none;
  position: relative;
}

/* .rbc-header + .rbc-header::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 1.25rem;
  background-color: #c4c5d6;
  margin-right: 2px;
} */

.rbc-header .rbc-button-link {
  margin-bottom: "2.5rem";
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.rbc-today .rbc-now {
  background-color: #f3f2fd;
}

.rbc-time-header-content {
  border-left: none;
  position: relative;
  padding-left: 1px;
}

.rbc-time-header-content::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 1.25rem;
  background-color: #c4c5d6;
}

.rbc-today {
  background-color: #f3f2fd;
}

.rbc-current-time-indicator {
  background-color: #ba1a1a;
  height: 2px;
}

.rbc-today .custom-header-value {
  padding-inline: 0.5rem;
  background-color: #2d55c9;
  color: #fff;
  border-radius: 100%;
  width: 38px;
  height: 36px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-today .custom-header-container {
  margin-bottom: 1.5rem;
}

.rbc-day-slot .rbc-time-column .rbc-time-slot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 3.87rem;
}

.rbc-time-gutter .rbc-time-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.rbc-events-container {
  margin-right: 5px !important;
}

.rbc-event-label {
  display: none;
}
